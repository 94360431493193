<template>
  <div class="divBox">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <!-- <v-button text="导入"></v-button> -->
        <v-button text="新增" @click="toAdd"></v-button>
        <!-- <v-button text="导出"></v-button> -->
      </template>
      <template #searchSlot>
        <area-select
          @changeModel="changeModel"
          specifyCollectionTypes="1"
          :disabled="true"
        />
        <!-- <community-select v-model="searchParam.communityId" label="选择园区" /> -->

        <v-select
          clearable
          :options="shelfStsOptions"
          v-model="searchParam.shelfSts"
          @change="$refs.list.search()"
          label="当前状态"
        />
        <v-select
          clearable
          :options="goodsClassifyOps"
          v-model="searchParam.goodsClassify"
          @change="$refs.list.search()"
          label="商品分类"
        />
        <v-select
          clearable
          :options="pointsSts"
          v-model="searchParam.sortType"
          @change="$refs.list.search()"
          label="积分排序"
        />
        <v-input label="商品名称" v-model="searchParam.title" />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="编辑"
          v-if="scope.row.canEdit == undefined || scope.row.canEdit"
          type="text"
          @click="toEdit(scope.row)"
        />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
        <v-button
          text="上架"
          type="text"
          v-if="scope.row.shelfSts == 0 || scope.row.shelfSts == 2"
          @click="changeShelfSts(scope.row, 1)"
        />
        <v-button
          text="下架"
          type="text"
          v-if="scope.row.shelfSts == 1"
          @click="changeShelfSts(scope.row, 2)"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import { PointsMallGoodsUpdShelfStsStatus } from "./api.js";
import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";
import {
  shelfSts,
  pointsSts,
  goodsClassifyMap,
  setGoodsClassifyOps,
} from "./map";
import { PointsMallGoodsDeleteById, PointsMallGoodsListUrl } from "./api";

export default {
  name: "pointsMallGoodsList",
  data() {
    return {
      pointsSts,
      shelfStsOptions: shelfSts,
      goodsClassifyOps: setGoodsClassifyOps(),
      searchParam: {
        spaceIds: null,
        goodsClassify: undefined,
        title: null,
        shelfSts: null,
        sortType: null,
      },
      tableUrl: PointsMallGoodsListUrl,
      headers: [
        {
          prop: "communityName",
          label: "园区名称",
        },
        {
          prop: "goodsClassify",
          label: "商品分类",
          formatter: (row, prop) => {
            return goodsClassifyMap[row[prop]];
          },
        },
        {
          prop: "imageUrl",
          label: "商品图片",
          align: "center",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "title",
          label: "商品名称",
        },
        {
          prop: "pointsPrice",
          align: "center",
          label: "所需积分",
        },
        {
          prop: "stock",
          align: "center",
          label: "库存",
        },
        {
          prop: "shelfSts",
          align: "center",
          label: "当前状态",
          formatter: (row, prop) => {
            for (let i = 0; i < shelfSts.length; i++) {
              if (row.shelfSts === Number(shelfSts[i].value)) {
                return shelfSts[i].label;
              }
            }
          },
        },
      ],
    };
  },
  components: {},
  mounted() {},
  computed: {},
  methods: {
    changeModel(value) {
      this.searchParam.spaceIds = value.spaceId;
    },
    toAdd() {
      this.$router.push({
        name: "goodsEdit",
      });
    },
    toEdit(data) {
      this.$router.push({
        name: "goodsEdit",
        query: { id: data.id, communityId: data.communityId },
      });
    },
    toDelete(data) {
      let message = "确定要删除" + data.title;

      this.$confirm(message, "确定", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post(`${PointsMallGoodsDeleteById}`, null, {
              params: {
                id: data.id,
              },
            })
            .then((res) => {
              if (res.code === 200) {
                this.$message.success("操作成功");
                this.$refs.list.search();
              } else {
                this.$message.error(res.msg);
              }
            });
        })
        .catch(() => {
          // Message.error('禁用失败！');
        });
    },
    changeShelfSts(data, shelfSts) {
      let message = "确定要上架" + data.title;
      if (shelfSts == 2) {
        message = "确定要下架" + data.title;
      }

      this.$confirm(message, "确定", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post(`${PointsMallGoodsUpdShelfStsStatus}`, null, {
              params: {
                id: data.id,
                shelfSts: shelfSts,
              },
            })
            .then((res) => {
              if (res.code === 200) {
                this.$message.success("操作成功");
                this.$refs.list.search();
              } else {
                this.$message.error(res.msg);
              }
            });
        })
        .catch(() => {
          // Message.error('禁用失败！');
        });
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
